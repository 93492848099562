export const FloorThreeList = [
	{
		avatar: require('@/assets/img/floor3/betania.png'),
		title:
			'“TiFi es una excelente opción si quieres aumentar la felicidad de tus colaboradores y dejar de lado la preocupación y el estrés.”',
		author: 'Betania Scarponetti',
		source: 'Headhunter'
	},
	{
		avatar: require('@/assets/img/floor3/luis.png'),
		title:
			'“Puedo ocupar TiFi en cualquier momento dependiendo de mis días laborados y eso me ayuda porque hay veces que uno llega un poquito justo a la quincena o sale un gasto imprevisto.”',
		author: 'Luis López',
		source: 'Usuario de TiFi'
	},
	{
		avatar: require('@/assets/img/floor3/erika.png'),
		title:
			'“TiFi es una App magnífica que te apoya rápidamente en el momento que más lo necesitas. A diferencia de un préstamo, TiFi no te cobra comisión, solo una pequeña cantidad por movimiento de cuenta.”',
		author: 'Erika Eslava',
		source: 'Usuario de TiFi'
	},
	{
		avatar: require('@/assets/img/floor3/Monse.png'),
		title:
			'“Me siento muy contenta y tranquila, porque este beneficio te libera. Si necesitas algo, puedes tener tu dinero en cualquier momento, no es necesario que esperes hasta la quincena.”',
		author: 'Monserrat Jiménez',
		source: 'Usuario de TiFi'
	}
]

export const FloorSevenList = [
	{
		title: require('@/assets/img/medios/Forbes.png'),
		msg_title: 'Esta empresa busca dar adelantos y créditos de nómina desde el celular.',
		msg: 'A través de una aplicación en su teléfono celular, sin costo alguno para las empresas y con una cuota fija para el empleado de sólo 39 pesos por retiro.',
		link: 'https://www.forbes.com.mx/esta-empresa-busca-dar-adelantos-y-creditos-de-nomina-desde-el-celular/'
	},
	{
		title: require('@/assets/img/medios/Milenio.png'),
		msg_title: 'Fintech TiFi llega a Mexico para apoyar con prestamos a empresas yesus empleados.',
		msg: 'Con operaciones en México desde agosto de 2020, el grupo ha generado oportunidades financieras a cerca de 400 mil mexicanos, a través de créditos personales por un monto total de mil 350 millones de pesos.',
		link: 'https://www.milenio.com/negocios/fintech-tifi-inicia-operaciones-en-mexico'
	},
	{
		title: require('@/assets/img/medios/publimetro.png'),
		msg_title: 'Tifi genera en México mayor inclusión financiera para empresas y empleados.',
		msg: 'Como una compañía de inclusión financiera y sólida aliada de las empresas, TiFi anuncia su entrada al mercado nacional con dos productos que serán de gran ayuda para los trabajadores…',
		link: 'https://www.publimetro.com.mx/mx/empresas/2021/08/26/tifi-genera-en-mexico-mayor-inclusion-financiera-para-empresas-y-empleados.html'
	},
	{
		title: require('@/assets/img/medios/MVS.jpg'),
		msg_title: 'TiFi llega a México con instrumentos financieros para empresas y trabajadores',
		msg: 'Llega al mercado nacional como una compañía de inclusión financiera y sólida aliada de las empresas.',
		link: 'https://mvsnoticias.com/noticias/economia/tifi-llega-a-mexico-con-instrumentos-financieros-para-empresas-y-trabajadores/'
	},
	{
		title: require('@/assets/img/medios/Uni2_noticias.jpg'),
		msg_title: 'Trabajadores podrán acceder a adelantos y créditos de nómina a través de aplicación.',
		msg: 'Con Adelanto de Nómina cualquier trabajador podrá solicitar el depósito a su cuenta bancaria de los días laborados en una quincena antes de que ésta concluya',
		link: 'https://www.uni2noticias.com/trabajadores-podran-acceder-a-adelantos-y-creditos-de-nomina-a-traves-de-aplicacion/'
	},
	{
		title: require('@/assets/img/medios/quadratin.png'),
		msg_title: 'TiFi llega al mercado mexicano para apoyar a empresas y sus empleados.',
		msg: 'CIUDAD DE MÉXICO, 25 de agosto de 2021.- Con la finalidad de fortalecer la fuerza laboral del país y hacer frente a los efectos que ha tenido la pandemia en la economía, la empresa TiFi llega al mercado mexicano con dos nuevos productos',
		link: 'https://mexico.quadratin.com.mx/tifi-llega-al-mercado-mexicano-para-apoyar-a-empresas-y-sus-empleados/'
	},
	{
		title: require('@/assets/img/medios/dia_fintech.jpg'),
		msg_title: 'TiFi anuncia su entrada a México con dos productos de ayuda para los trabajadores.',
		msg: 'TiFi, la empresa de inclusión financiera que acaba de anunciar su llegada a nuestro país con dos productos: Adelanto y Crédito de nómina.',
		link: 'https://diafintech.com.mx/noticia/tifi-anuncia-su-entrada-a-mexico-con-dos-productos-de-ayuda-para-los-trabajadores/'
	},
	{
		title: require('@/assets/img/medios/hablemos_dinero.png'),
		msg_title: 'TIFI, adelantos y créditos de nómina a través de una aplicación.',
		msg: 'Con operaciones en México desde agosto de 2020, el grupo ha generado oportunidades financieras a cerca de 400 mil mexicanos, a través de créditos personales por un monto total de mil 350 millones de pesos.',
		link: 'http://hablemosdedinero.com.mx/nota/tifi-adelantos-y-creditos-de-nomina-a-traves-de-una-aplicacion'
	}
]
