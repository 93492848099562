<template>
	<div style="width:100%">
		<div class="weba">
			<div class="close_btn" v-if="isShow" @click="Close">Cerrar</div>
      <slot name="left_container"></slot>
      <div class="right_container">
        <div class="title_box clearfix">
          <div class="title_list" :class="active == 'a' ? 'active' : ''" @click="active = 'a'">Adelanto de nómina</div>
          <div class="title_list" :class="active == 'b' ? 'active' : ''" @click="active = 'b'">Crédito de nómina</div>
        </div>
        <div class="right_box">
          <div class="right_box_title_box">
            <!-- 周 -->
            <div class="right_box_title_box_list" :class="titleT == 'a' ? 'active' : ''" @click="ChangeTitle('a')">
              Semanal
            </div>

            <!-- 双周 -->
            <div class="right_box_title_box_list" :class="titleT == 'b' ? 'active' : ''" @click="ChangeTitle('b')">
              Quincenal
            </div>

            <!-- 月 -->
            <div class="right_box_title_box_list" :class="titleT == 'c' ? 'active' : ''" @click="ChangeTitle('c')">
              Mensual
            </div>
          </div>
          <div v-show="active == 'b'">
            <div class="r_list_box clearfix">
              <div class="r_list_box_t clearfix">
                <div class="r_list_box_t_l">Sueldo quincenal</div>
                <div class="r_list_box_t_r">
                  <div class="btn">-</div>
                  <div class="txt"><span>$</span> {{ value1 | toThousands }}</div>
                  <div class="btn">+</div>
                </div>
              </div>
              <vue-slider v-model="value1" :min="1000" :max="12000" :interval="100"></vue-slider>
            </div>
            <div class="r_list_box clearfix">
              <div class="r_list_box_t clearfix">
                <div class="r_list_box_t_l">Cantidad Requerida</div>
                <div class="r_list_box_t_r">
                  <div class="btn">-</div>
                  <div class="txt"><span>$</span>{{ value2 | toThousands }}</div>
                  <div class="btn">+</div>
                </div>
              </div>
              <vue-slider v-model="value2" :min="1000" :max="12000" :interval="100"></vue-slider>
            </div>
            <div class="r_list_box clearfix">
              <div class="r_list_box_t clearfix">
                <div class="r_list_box_t_l">Plazo</div>
                <div class="r_list_box_t_r">
                  <div class="btn">-</div>
                  <div class="txt">{{ value3 | toWeek }}</div>
                  <div class="btn">+</div>
                </div>
              </div>
              <vue-slider v-model="value3" :min="3" :max="36" :interval="1"></vue-slider>
            </div>
            <div class="right_box_txt_box">
              <div class="right_box_txt_box_l">Total pre-aprobado</div>
              <div class="right_box_txt_box_r">
                <span>$</span>
                15,000
              </div>
            </div>
          </div>
          <div v-show="active == 'a'">
            <div class="r_list_box clearfix">
              <div class="r_list_box_t clearfix">
                <div class="r_list_box_t_l">{{ title }}</div>
                <div class="r_list_box_t_r">
                  <div class="btn" @click="ReduceValue4">-</div>
                  <div class="txt"><span>$</span> {{ value4 | toThousands }}</div>
                  <div class="btn" @click="AddValue4">+</div>
                </div>
              </div>
              <vue-slider v-model="value4" :min="min4" :max="max4" :interval="100"></vue-slider>
            </div>
            <div class="r_list_box clearfix">
              <div class="r_list_box_t clearfix">
                <div class="r_list_box_t_l">Días solicitados</div>
                <div class="r_list_box_t_r">
                  <div class="btn" @click="ReduceValue5">-</div>
                  <div class="txt">{{ value5 | toDias }}</div>
                  <div class="btn" @click="AddValue5">+</div>
                </div>
              </div>
              <vue-slider v-model="value5" :min="min5" :max="max5" :interval="1"></vue-slider>
            </div>
            <div class="right_box_txt_box">
              <div class="right_box_txt_box_l">Total de adelanto</div>
              <div class="right_box_txt_box_r">
                <span>$</span>
                {{ value4 | toNum(value5, titleT) | toThousands }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

		<!-- <div class="mobilea">
			<div class="close_btn" v-if="isShow" @click="Close">Cerrar</div>

      <slot name="left_container"></slot>
			<div class="title_box clearfix">
				<div class="title_list" :class="active == 'a' ? 'active' : ''" @click="active = 'a'">Adelanto de nómina</div>
				<div class="title_list" :class="active == 'b' ? 'active' : ''" @click="active = 'b'">Crédito de nómina</div>
			</div>
			<div class="c_box">
				<div class="right_box_title_box">
					<div class="right_box_title_box_list" :class="titleT == 'a' ? 'active' : ''" @click="ChangeTitle('a')">
						Semanal
					</div>
					<div class="right_box_title_box_list" :class="titleT == 'b' ? 'active' : ''" @click="ChangeTitle('b')">
						Quincenal
					</div>
					<div class="right_box_title_box_list" :class="titleT == 'c' ? 'active' : ''" @click="ChangeTitle('c')">
						Mensual
					</div>
				</div>
				<div v-show="active == 'b'">
					<div class="r_list_box clearfix">
						<div class="r_list_box_t clearfix">
							<div class="r_list_box_t_l">Sueldo quincenal</div>
							<div class="r_list_box_t_r">
								<div class="btn">-</div>
								<div class="txt"><span>$</span> {{ value1 | toThousands }}</div>
								<div class="btn">+</div>
							</div>
						</div>
						<vue-slider v-model="value1" :min="1000" :max="12000" :interval="100"></vue-slider>
					</div>
					<div class="r_list_box clearfix">
						<div class="r_list_box_t clearfix">
							<div class="r_list_box_t_l">Cantidad Requerida</div>
							<div class="r_list_box_t_r">
								<div class="btn">-</div>
								<div class="txt"><span>$</span> {{ value2 | toThousands }}</div>
								<div class="btn">+</div>
							</div>
						</div>
						<vue-slider v-model="value2" :min="1000" :max="12000" :interval="100"></vue-slider>
					</div>
					<div class="r_list_box clearfix">
						<div class="r_list_box_t clearfix">
							<div class="r_list_box_t_l">Plazo</div>
							<div class="r_list_box_t_r">
								<div class="btn">-</div>
								<div class="txt">{{ value3 | toWeek }}</div>
								<div class="btn">+</div>
							</div>
						</div>
						<vue-slider v-model="value3" :min="3" :max="36" :interval="1"></vue-slider>
					</div>
					<div class="right_box_txt_box">
						<div class="right_box_txt_box_l">Total pre-aprobado</div>
						<div class="right_box_txt_box_r">
							<span>$</span>
							15,000
						</div>
					</div>
				</div>
				<div v-show="active == 'a'">
					<div class="r_list_box clearfix">
						<div class="r_list_box_t clearfix">
							<div class="r_list_box_t_l">{{ title }}</div>
							<div class="r_list_box_t_r">
								<div class="btn" @click="ReduceValue4">-</div>
								<div class="txt"><span>$</span> {{ value4 | toThousands }}</div>
								<div class="btn" @click="AddValue4">+</div>
							</div>
						</div>
						<vue-slider v-model="value4" :min="min4" :max="max4" :interval="100"></vue-slider>
					</div>
					<div class="r_list_box clearfix">
						<div class="r_list_box_t clearfix">
							<div class="r_list_box_t_l">Días solicitados</div>
							<div class="r_list_box_t_r">
								<div class="btn" @click="ReduceValue5">-</div>
								<div class="txt">{{ value5 | toDias }}</div>
								<div class="btn" @click="AddValue5">+</div>
							</div>
						</div>
						<vue-slider v-model="value5" :min="min5" :max="max5" :interval="1"></vue-slider>
					</div>
					<div class="right_box_txt_box">
						<div class="right_box_txt_box_l">Total de adelanto</div>
						<div class="right_box_txt_box_r">
							<span>$</span>
							{{ value4 | toNum(value5, titleT) | toThousands }}
						</div>
					</div>
				</div>
				<div class="btn_b" v-if="isShow">
					<div class="btn_tx" @click="GoToUrl2">Afliliarme ahora</div>
				</div>
			</div>
		</div> -->
	</div>
</template>
<script>
import VueSlider from 'vue-slider-component'
export default {
	props: {
		isShow: Boolean
	},
	data() {
		return {
			active: 'a',
			value1: '12000',
			value2: '3000',
			value3: '12',
			value4: '3000',
			min4: 1000,
			max4: 6000,
			value5: '5',
			min5: 2,
			max5: 15,
			titleT: 'b',
			title: 'Sueldo quincenal'
		}
	},
	created() {},
	methods: {
		GoToUrl(url) {
			this.$router.push(url)
		},
		GoLink(url) {
			window.open(url)
		},
		Close() {
			this.$emit('Close')
		},
		GoToUrl2() {
			if (this.isShow) {
				window.open('https://share.hsforms.com/18bKKHI6hRcCzwQmVq67nkwc01rb')
			} else {
				this.$router.push('/empresas')
			}
		},
		ChangeTitle(str) {
			if (this.titleT == str) {
				return false
			} else {
				if (str == 'a') {
					this.titleT = 'a'
					this.title = 'Sueldo semanal'
					this.value4 = 2000
					this.value5 = 3
					this.max4 = 3000
					this.max5 = 7
				} else if (str == 'b') {
					this.titleT = 'b'
					this.title = 'Sueldo quincenal'
					this.value4 = 2000
					this.value5 = 3
					this.max4 = 6000
					this.max5 = 15
				} else if (str == 'c') {
					this.titleT = 'c'
					this.title = 'Sueldo mensual'
					this.value4 = 2000
					this.value5 = 3
					this.max4 = 12000
					this.max5 = 30
				}
			}
		},
		ReduceValue4() {
			if (this.value4 > this.min4) {
				this.value4 = parseInt(this.value4) - 100
			} else {
				return false
			}
		},
		ReduceValue5() {
			if (this.value5 > this.min5) {
				this.value5 = parseInt(this.value5) - 1
			} else {
				return false
			}
		},
		AddValue4() {
			if (this.value4 < this.max4) {
				this.value4 = parseInt(this.value4) + 100
			} else {
				return false
			}
		},
		AddValue5() {
			if (this.value5 < this.max5) {
				this.value5 = parseInt(this.value5) + 1
			} else {
				return false
			}
		}
	},
	components: {
		VueSlider
	},
	filters: {
		toThousands(num) {
			var result = [],
				counter = 0
			num = (num || 0).toString().split('')
			for (var i = num.length - 1; i >= 0; i--) {
				counter++
				result.unshift(num[i])
				if (!(counter % 3) && i != 0) {
					result.unshift(',')
				}
			}
			return result.join('')
		},
		toWeek(num) {
			return num + ' Meses'
		},
		toDias(num) {
			return num + ' Días'
		},
		toNum(num1, num2, type) {
			if (type == 'a') {
				return parseInt((Number(num1) * Number(num2)) / 7)
			} else if (type == 'b') {
				return parseInt((Number(num1) * Number(num2)) / 15)
			} else if (type == 'c') {
				return parseInt((Number(num1) * Number(num2)) / 30)
			}
		}
	}
}
</script>
<style lang="scss">
$themeColor: #fa5a5a;
@import '~vue-slider-component/lib/theme/default.scss';
.weba {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 35px 0 0;
  box-sizing: border-box;
  border-radius: 24px;
  background-color: #ffffff;
  .close_btn {
    cursor: pointer;
    position: absolute;
    right: 37px;
    top: 45px;
    font-family: 'inter';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  .right_container {
    .title_box {
      height: 40px;
      margin: 0 auto;
      line-height: 40px;
      font-size: 16px;
      font-family: 'inter';
      font-style: normal;
      font-weight: normal;
      text-align: center;
      background-color: #fecece;
      border-radius: 40px;
      overflow: hidden;
      color: #354d74;
      .title_list {
        width: 50%;
        height: 100%;
        float: left;
        cursor: pointer;
        &.active {
          background: #fa5a5a;
          color: #ffffff;
          border-radius: 40px;
        }
      }
    }
    .right_box {
      width: 570px;
      padding: 32px 58px;
      box-sizing: border-box;
      margin-top: 38px;
      display: inline-block;
      vertical-align: top;
      background: #ffffff;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      .right_box_title_box {
        width: 302px;
        height: 20px;
        padding: 2px;
        border: 1px solid #fa5a5a;
        margin: 0 auto;
        border-radius: 80px;
        display: flex;
        justify-content: space-between;
        .right_box_title_box_list {
          width: 30%;
          text-align: center;
          height: 100%;
          line-height: 20px;
          color: #1e3964;
          font-family: 'inter';
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          cursor: pointer;
          &.active {
            background: #fa5a5a;
            border-radius: 80px;
            color: #ffffff;
          }
        }
      }
      .r_list_box {
        margin-top: 32px;
        .r_list_box_t {
          margin-bottom: 12px;
          .r_list_box_t_l {
            float: left;
            height: 38px;
            line-height: 40px;
            font-family: 'inter';
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: #000000;
          }
          .r_list_box_t_r {
            float: right;
            .btn {
              display: inline-block;
              vertical-align: top;
              width: 40px;
              height: 40px;
              border: 1px solid #8e9cb1;
              box-sizing: border-box;
              border-radius: 8px;
              text-align: center;
              line-height: 40px;
              font-size: 20px;
              color: #1e3964;
              cursor: pointer;
            }
            .txt {
              display: inline-block;
              vertical-align: top;
              width: 160px;
              height: 40px;
              border: 1px solid #8e9cb1;
              box-sizing: border-box;
              border-radius: 8px;
              text-align: center;
              line-height: 40px;
              color: #1e3964;
              margin: 0 8px;
              position: relative;
              span {
                position: absolute;
                left: 10px;
                top: 10px;
                font-family: 'inter';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #1e3964;
              }
            }
          }
        }
      }
      .right_box_txt_box {
        margin-top: 32px;
        height: 40px;
        line-height: 40px;
        .right_box_txt_box_l {
          font-family: 'inter';
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          display: inline-block;
          vertical-align: top;
          margin: 0 40px;
        }
        .right_box_txt_box_r {
          display: inline-block;
          vertical-align: middle;
          padding-right: 20px;
          background: #f6faf0;
          border-radius: 8px;
          font-family: 'po';
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          color: #a9ca6c;
          span {
            display: inline-block;
            vertical-align: middle;
            line-height: 40px;
            margin: 0 16px;
            color: #a9ca6c;
            font-family: 'iner';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1246px) {
  .weba {
    flex-wrap: wrap;
    .right_container {
      margin: 0 auto;
      .title_box {
        height: 50px;
        margin: 20px auto;
        .title_list {
          line-height: 50px;
        }
      }
      .right_box {
        width: auto;
        .right_box_title_box {
          width: 500px;
          height: 35px;
          .right_box_title_box_list {
            line-height: 35px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .weba {
    .right_container {
      .right_box {
        .right_box_title_box {
          width: 650px;
        }
      }
    }
  }
}
</style>
