<template>
  <div>
    <div class="floor_two">
      <template v-if="!$store.state.isH5">
        <h1 class="common_title">TiFi no es un crédito bancario,</h1>
        <h1 class="common_title">sino un beneficio sobre el sueldo trabajado</h1>
      </template>
      <template v-else>
        <h1 class="common_title">TiFi no es un crédito bancario,sino un beneficio sobre el sueldo trabajado</h1>
      </template>
      <div class="floor_two_box">
        <div class="left_box">
          <h3>Beneficios para tu equipo:</h3>
          <ul>
            <li>• Acceso a su salario 24/7</li>
            <li>• Sin endeudamiento</li>
            <li>• Sin intereses</li>
            <li>• Sin trámites engorrosos</li>
            <li>• Rápido y seguro</li>
            <li>• Tranquilidad y estabilidad financiera</li>
            <li>• Prestación adicional</li>
          </ul>
        </div>
        <div class="center_line"></div>
        <div class="right_box">
          <h3>Beneficios para tu empresa:</h3>
          <ul>
            <li>• Sin costo</li>
            <li>• Sin riesgo financiero</li>
            <li>• Sin cambios en el proceso de nómina</li>
            <li>• Disminución del estrés laboral</li>
            <li>• Retención de personal</li>
            <li>• Empleados más motivados</li>
            <li>• Mayor productividad y compromiso</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="floor_bottom">
      <template v-if="!$store.state.isH5">
        <h1 class="common_title">¡Sé promotor de la inclusión financiera</h1>
        <h1 class="common_title text_btm">dentro de tu empresa!</h1>
      </template>
      <template v-else>
        <h1 class="common_title">¡Sé promotor de la inclusión financiera dentro de tu empresa!</h1>
      </template>
      <a href="https://contacto.tifi.com.mx/demo"><div class="btn_txt">Agenda una demo</div></a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    GoLink: {
      type: Function,
      require: true
    }
  }
}
</script>

<style scoped lang='scss'>
.floor_two {
  padding: 50px 30px;
  h1 {
    margin: 0 auto;
    text-align: center;
    color: var(--primary-blue);
    width: 80%;
  }
  .floor_two_box {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    padding: 50px 0;
    .center_line {
      border-right: var(--primary-red) solid 1px;
    }
    .left_box, .right_box {
      h3 {
        color: var(--primary-red);
        font-size: 20px;
        font-weight: bold;
      }
      ul {
        list-style: circle !important;
        li {
          font-family: 'Poppins SemiBold 600 Italic', sans-serif;
          padding: 10px 0;
          font-size: 20px;
        }
      }
    }
  }
}
.floor_bottom {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  background-color: var(--primary-blue);
  color: #ffffff;
  text-align: center;
  h1 {
    color: #ffffff;
    // padding-top: 45px;
  }
  .text_btm {
    padding-top: 5px;
    // margin-bottom: 15px;
  }
  .btn_txt {
    margin-top: 50px;
    // margin: 55px 0;
    background: #f85d5c;
    border-radius: 44px;
    padding: 10px 32px;
    height: 44px;
    line-height: 44px;
    cursor: pointer;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    display: inline-block;
    a {
      color: #fff;
    }
  }
}
@media screen and (max-width: 1246px) {
  .floor_two {
    padding: 100px 0;
    h1 {
      width: 95%;
    }
    .floor_two_box {
      padding-bottom: 0;
      flex-direction: column;
      .center_line {
        border-right: none;
        background-color: red;
        height: 1px;
        width: 70%;
        margin: 30px 0;
      }
      .left_box, .right_box {
        h3 {
          font-size: 40px;
        }
        ul {
          li {
            font-size: 37px;
          }
        }
      }
    }
  }
  .floor_bottom {
    h1 {
      margin: 0 auto;
      width: 95%;
    }
    .btn_txt {
      margin-top: 60px;
      font-size: 36px;
    }
  }
}
</style>
