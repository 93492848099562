<template>
  <div>
    <div class="floor4">
      <template v-if="!$store.getters.GetIsH5">
        <div class="title1 common_title">
          Las finanzas personales son causa
          <p class="common_title">del estrés laboral</p>
        </div>
      </template>
      <template v-else>
        <div class="title1 common_title">
          Las finanzas personales son causa del estrés laboral
        </div>
      </template>
      <template v-if="!$store.getters.GetIsH5">
        <div class="list_box">
          <div class="img_list1">
            <h2 class="common_title">60%</h2>
            <p>de los trabajadores viven preocupados por su situación financiera</p>
          </div>
          <div class="img_list2">
            <h1 class="common_title">75%</h1>
            <p>de los mexicanos padece fatiga por estrés laboral</p>
          </div>
          <div class="img_list3">
            <h2 class="common_title">63%</h2>
            <p>de los empleados han sufrido estrés laboral en los últimos 2 años</p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="list_box">
          <div class="img_list2">
            <h1 class="common_title">75%</h1>
            <p>de los mexicanos padece fatiga por estrés laboral</p>
          </div>
          <div class="img_list1">
            <h2 class="common_title">60%</h2>
            <p>de los trabajadores viven preocupados por su situación financiera</p>
          </div>
          <div class="img_list3">
            <h2 class="common_title">63%</h2>
            <p>de los empleados han sufrido estrés laboral en los últimos 2 años</p>
          </div>
        </div>
      </template>
      <div class="title2">Fuente: Instituto Mexicanos del Seguro Social | Asociación Mexicana de Internet </div>
    </div>
    <div class="floor4_bottom">
      <template v-if="!$store.getters.GetIsH5">
        <h1 class="common_title">¡Di SÍ al adelanto de nómina</h1>
        <h1 class="h1_pb common_title">y olvídate del estrés financiero!</h1>
      </template>
      <template v-else>
        <h1 class="common_title h1_pb">¡Di SÍ al adelanto de nómina y olvídate del estrés financiero!</h1>
      </template>
      <span class="bottom_btn" @click="goPage('step04')">Contáctanos</span>
    </div>
  </div>
</template>
<script>
import { goScrollToPage } from '@/utils'
export default {
  computed: {
    goPage() {
      return goScrollToPage
    }
  }
}
</script>
<style scoped lang='scss'>
@import '@/assets/css/global.scss';
.floor4 {
  width: 1440px;
  margin: 0 auto;
  .title1 {
    width: 1000px;
    margin: 0 auto;
    font-style: normal;
    line-height: 54px;
    text-align: center;
    color: var(--primary-blue);
    p {
      font-style: normal;
      line-height: 40px;
    }
  }
  .list_box {
    padding: 100px 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img_list1, .img_list3{
      overflow: hidden;
      position: relative;
      width: 400px;
      height: 400px;
      &::after {
        content: ' ';
        position: absolute;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
      }
      h2 {
        position: absolute;
        top:23%;
        left: 50%;
        transform: translateX(-50%);
        color: var(--primary-red);
      }
      p {
        position: absolute;
        top:34%;
        left: 50%;
        transform: translateX(-50%);
        width: 45%;
        font-size: 20px;
        word-break: break-word;
        text-align: center;
        font-family: 'Poppins SemiBold 600 Italic', sans-serif;
      }
    }
    .img_list1 {
      &::after {
        background-image: url('~@/assets/img/home/circulo1.svg');
        transform: rotate(180deg) scale(1.3, 1.3);
      }
    }
    .img_list3{
      h2 {
        top:23%;
      }
      p {
        top:37%;
      }
    }
    .img_list2 {
      overflow: hidden;
      position: relative;
      background-repeat: no-repeat;
      background-image: url('~@/assets/img/home/circulo2.svg');
      transform: scale(1.3, 1.3);
      background-size: 100% 100%;
      height: 412px;
      width: 412px;
      h1 {
        position: absolute;
        top:27%;
        left: 50%;
        transform: translateX(-50%);
        color: var(--primary-red);
      }
      p {
        position: absolute;
        top:42%;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
        font-size: 28px;
        line-height: 35px;
        word-break: break-word;
        text-align: center;
        color: #fff;
        font-family: 'Poppins SemiBold 600 Italic', sans-serif;
      }
    }
    .img_list3 {
      &::after {
        background-image: url('~@/assets/img/home/circulo1.svg');
        transform: rotate(90deg) scale(1.3, 1.3);
      }
    }
  }
  .title2 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 15px;
    color: var(--primary-blue);
    text-align: center;
    margin-bottom: 60px;
  }
  .box_nt {
    width: 880px;
    margin: 0 auto;
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 54px;
      color: var(--primary-blue);
      margin-bottom: 40px;
      text-align: center;
      span {
        color: var(--primary-red);
      }
    }
    .content_box {
      padding: 32px 32px 22px 30.5px;
      background: #fafafa;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      display: inline-block;
      vertical-align: top;
      &.mr20 {
        margin-right: 80px;
      }
      .penson_icon {
        display: inline-block;
        vertical-align: top;
        width: 63px;
        height: 63px;
        border-radius: 50%;
        margin-right: 14.5px;
      }
      .txt1 {
        width: 260px;
        display: inline-block;
        vertical-align: top;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        color: var(--primary-blue);
      }
      .txt2 {
        margin-top: 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: var(--primary-blue);
        text-align: right;
        span {
          color: #a9ca6c;
        }
      }
    }
  }
}
.floor4_bottom {
  background-color: var(--primary-red);
  text-align: center;
  padding: 50px 0;
  padding-bottom: 80px;
  h1 {
    color: #fff;
  }
  .h1_pb {
    padding-top: 0px;
    margin-bottom: 60px;
  }
  .bottom_btn {
    font-size: 17px;
    padding: 20px 80px;
    font-weight: bold;
    border-radius: 50px;
    background-color: var(--primary-blue);
    color: #fff;
    cursor: pointer;
  }
}

@media screen and (max-width: 1246px) {
  .floor4 {
    width: 100%;
    .title1 {
      width: 90%;
    }
    .list_box {
      width: 100%;
      flex-wrap: wrap;
      .img_list2 {
        width: 80%;
        margin: 0 auto;
        p {
          width: 50%;
        }
      }
      .img_list1, .img_list3 {
        width: 405px;
        height: 400px;
        h2 {
          font-size: 37px;
        }
        p {
          position: absolute;
          left: 50%;
          top: 37%;
          font-size: 35px;
          line-height: 35px;
          transform: translateX(-50%) scale(.7);
          width: 75%;
          height: 39%;
          @include text-ellipsis(4)
        }
        &::after {
          background-size: 100% 100%;
        }
      }
    }
    .title2 {
      width: 100%;
      font-size: 37px;
      line-height: normal;
      transform: scale(.8);
    }
  }

  .floor4_bottom {
    padding: 40px 80px 80px 80px;
    .bottom_btn {
      font-weight: bold;
      font-size: 37px;
    }
  }
}
</style>
