export default [
	{
		name: 'Forbes',
		title: 'Esta empresa busca dar adelantos y créditos de nómina desde el celular',
		link: 'https://www.forbes.com.mx/esta-empresa-busca-dar-adelantos-y-creditos-de-nomina-desde-el-celular/',
		head: require('@/assets/img/media/forbes.svg'),
		date: '26.08.2021'
	},
	{
		name: 'Milenio',
		title:
			'Fintech <b>Ti<span class="red">Fi</span></b> llega a México para apoyar con préstamos a empresas y sus empleados',
		link: 'https://www.milenio.com/negocios/fintech-tifi-inicia-operaciones-en-mexico ',
		head: require('@/assets/img/media/milenio.svg'),
		date: '26.08.2021'
	},
	{
		name: 'Político Mx',
		title:
			'<b>Ti<span class="red">Fi</span></b> incursiona en México mediante opciones de financiamiento para trabajadores',
		link: 'https://politico.mx/minuta-politica/tifi-incursiona-en-méxico-mediante-opciones-de-financiamiento-para-trabajadores/',
		head: require('@/assets/img/media/politico.svg'),
		date: '26.08.2021'
	},
	{
		name: 'El Financiero  ',
		title: 'El top 10 para recordar a ORO en Pemex',
		link: 'https://www.elfinanciero.com.mx/opinion/dario-celis/2021/08/26/el-top-10-para-recordar-a-oro-en-pemex/',
		head: require('@/assets/img/media/ef.svg'),
		date: '26.08.2021'
	},
	{
		name: 'MVS',
		title:
			'<b>Ti<span class="red">Fi</span></b> llega a México con instrumentos financieros para empresas y trabajadores',
		link: 'https://mvsnoticias.com/noticias/economia/tifi-llega-a-mexico-con-instrumentos-financieros-para-empresas-y-trabajadores ',
		head: require('@/assets/img/media/mvs.svg'),
		date: '26.08.2021'
	},
	{
		name: 'Quadratín',
		title: '<b>Ti<span class="red">Fi</span></b> llega al mercado mexicano para apoyar a empresas y sus empleados',
		link: 'https://mexico.quadratin.com.mx/tifi-llega-al-mercado-mexicano-para-apoyar-a-empresas-y-sus-empleados/ ',
		head: require('@/assets/img/media/quatr.svg'),
		date: '26.08.2021'
	},
	{
		name: 'Hablemos de dinero',
		title: '<b>Ti<span class="red">Fi</span></b>, adelantos y créditos de nómina a través de una aplicación',
		link: 'http://hablemosdedinero.com.mx/nota/tifi-adelantos-y-creditos-de-nomina-a-traves-de-una-aplicacion',
		head: require('@/assets/img/media/habl.svg'),
		date: '26.08.2021'
	},
	{
		name: 'Día Fintech',
		title:
			'<b>Ti<span class="red">Fi</span></b> anuncia su entrada a México con dos productos de ayuda para los trabajadores',
		link: 'https://diafintech.com.mx/noticia/tifi-anuncia-su-entrada-a-mexico-con-dos-productos-de-ayuda-para-los-trabajadores/',
		head: require('@/assets/img/media/dia.svg'),
		date: '26.08.2021'
	},
	{
		name: 'Publimetro',
		title: '<b>Ti<span class="red">Fi</span></b> genera en México mayor inclusión financiera para empresas y empleados',
		link: 'https://www.publimetro.com.mx/mx/empresas/2021/08/26/tifi-genera-en-mexico-mayor-inclusion-financiera-para-empresas-y-empleados.html ',
		head: require('@/assets/img/media/publimetr.svg'),
		date: '26.08.2021'
	},
	{
		name: 'Uni2Noticias',
		title: 'Trabajadores podrán acceder a adelantos y créditos de nómina a través de aplicación',
		link: 'https://www.uni2noticias.com/trabajadores-podran-acceder-a-adelantos-y-creditos-de-nomina-a-traves-de-aplicacion/',
		head: require('@/assets/img/media/uni2.svg'),
		date: '26.08.2021'
	}
]
