<template>
  <div>
    <div class="floor1">
      <div class="content">
        <div class="left_box">
          <h1 class="title1 common_title">Libera a tus colaboradores</h1>
          <h1 class="title1 common_title">delestrés financiero</h1>
          <div class="title2 common_content">
            Con TiFi ofrece adelantos y préstamos de nómina a tus empleados
            para que dispongan de su salario trabajado cuando lo necesiten.
          </div>
          <div class="btn_txt" @click="goPage('step04')">
            ¡Afíliate GRATIS aquí!
          </div>
        </div>
        <div class="right_box">
          <img src="~@/assets/img/home/Lark.png" alt="" class="img_content" />
          <!-- <div class="floor1_title">Próximamente en</div> -->
          <img
            src="~@/assets/img/GooglePlay.png"
            alt=""
            class="google_icon"
            @click="GoLink('https://play.google.com/store/apps/details?id=mx.com.tifi.credito')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goScrollToPage } from '@/utils'
export default {
    props: {
      GoLink: {
        type: Function,
        require: true
      }
    },

    computed: {
      goPage() {
        return goScrollToPage
      }
    },
};
</script>

<style scoped lang='scss'>
.floor1 {
  width: 100%;
  height: 700px;
  box-sizing: border-box;
  background-color: #dfeffd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .content {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .left_box {
      display: inline-block;
      vertical-align: top;
      padding-top: 180px;
      padding-left: 36px;
      .title1 {
        font-style: normal;
        color: var(--primary-blue);
      }
      .title2 {
        margin: 36px 0 58px;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        color: var(--primary-blue);
      }
      .btn_txt {
        font-weight: bold;
        background: var(--primary-red);
        border-radius: 44px;
        padding: 10px 32px;
        height: 44px;
        line-height: 44px;
        cursor: pointer;
        color: #fff;
        font-style: normal;
        font-size: 20px;
        display: inline-block;
      }
    }
    .right_box {
      padding-top: 50px;
      margin-left: 50px;
      text-align: center;
      .img_content {
        display: inline-block;
        vertical-align: top;
        width: 560px;
        height: 503px;
      }
        .floor1_title {
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: var(--primary-blue);
          margin: 32px 0 18px;
        }
      .google_icon {
        margin-top: 20px;
        margin-left: 220px;
        width: 180px;
        display: block;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 1246px){
  .floor1 {
    height: auto;
    .content {
      flex-wrap: wrap;
      .left_box {
        padding-top: 100px;
        padding-left: 0;
        .title1 {
          width: 500px;
        }
        .title2 {
          width: 550px;
          line-height: 50px;
          font-size: 37px;
        }
        .btn_txt {
          font-weight: bold;
          font-size: 32px;
        }
      }
      .right_box {
        padding-left: 45px;
        padding-bottom: 50px;
      }
    }
  }
}
</style>
