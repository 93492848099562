<template>
  <div class="floor6" ref="floor6">
    <div class="content">
      <calculator :isShow="false">
        <template #left_container>
          <div class="left_title">
            <p class="title common_title">Calcula cuánto salario</p>
            <p class="title common_title">puedes solicitar con TiFi </p>
          </div>
        </template>
      </calculator>
    </div>
  </div>
</template>

<script>
import Calculator from '@/components/Calculator'
import { baseScroll } from '@/utils'
export default {
  components: {
    Calculator
  },
  mounted () {
    baseScroll(this.$refs.floor6, 'step03')
  }
}
</script>

<style scoped lang='scss'>
.floor6 {
  width: 90%;
  margin: 0 auto;
  padding: 10px 0;
  padding-bottom: 50px;
  .content {
    .left_title {
      width: 40%;
      height: 100px;
      .title {
        word-spacing: -3px;
        font-style: normal;
        text-align: center;
        letter-spacing: 1px;
        color: var(--primary-blue);
      }
    }
  }
}

@media screen and (max-width: 1246px) {
  .floor6 {
    padding-bottom: 100px;
    .content {
      .left_title {
        width: 100%;
        margin-bottom: 68px;
        .title {
          padding: 0;
          letter-spacing: normal;
          width: 100%;
        }
      }
    }
  }
}
</style>
