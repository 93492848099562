<template>
  <div class="floor_seven">
    <div class="content1">
      <h1 class="title common_title">Inclusión financiera inspirada por la tecnología</h1>
      <template v-if="!$store.getters.GetIsH5">
        <p class="msg common_content">Innovamos y cambiamos de la mano de la tecnología la manera en la que se ofrecen</p>
        <p class="msg msg_pb common_content">los servicios financieros, para acercarlos a millones de mexicanos.</p>
      </template>
      <template v-else>
        <p class="msg msg_pb common_content">Innovamos y cambiamos de la mano de la tecnología la manera en la que se ofrecen los servicios financieros, para acercarlos a millones de mexicanos.</p>
      </template>
      <span class="btn" @click="goScrollToPage('step04')">Conócenos</span>
    </div>
    <div class="content2" ref="floor7">
      <h1 class="main-title">¿Quiénes somos?</h1>

      <template v-if="!$store.getters.GetIsH5">
        <p class="p_pt common_content">Somos una empresa global que busca mejorar el bienestar de las personas a través de</p>
        <p>servicios financieros accesibles. Formamos parte del Grupo MIB, el cual cuenta con múltiples empresas</p>
        <p class="p_pb common_content">cotizando en la bolsa de valores de Hong Kong y un estrecho vínculo de inversión con Alibaba y DiDi.</p>
      </template>
      <template v-else>
        <p class="p_pt p_pb common_content">Somos una empresa global que busca mejorar el bienestar de las personas a través de servicios financieros accesibles. Formamos parte del Grupo MIB, el cual cuenta con múltiples empresas cotizando en la bolsa de valores de Hong Kong y un estrecho vínculo de inversión con Alibaba y DiDi. </p>
      </template>

      <div class="img_list">
        <img class="img01" src="@/assets/img/company/tar_logo.png" alt="">
        <img class="img02" src="@/assets/img/company/movista.png" alt="">
        <template v-if="$store.getters.GetIsH5">
          <img class="img06" src="@/assets/img/company/ggv-capital.png" alt="">
        </template>
        <img class="img03"  src="@/assets/img/company/alibaba-logo.png" alt="">
        <img class="img04" src="@/assets/img/company/didi.png" alt="">
      </div>
      <div class="img_list">
        <img class="img05" src="@/assets/img/company/gsr_logo.png" alt="">
        <template v-if="!$store.getters.GetIsH5">
          <img class="img06" s src="@/assets/img/company/ggv-capital.png" alt="">
        </template>
        <img class="img07"  src="@/assets/img/company/zhen.png" alt="">
      </div>
    </div>
    <div class="content3">
      <h1 class="common_title">Los medios hablan de nosotros</h1>
      <el-carousel :interval="5000" arrow="never" trigger="click" :autoplay="true">
        <el-carousel-item v-for="(item, index) in dataList" :key="item.author">
          <div class="swiper_container">
            <a :href="item.link">
              <img :src="item.title" class="swiper_title_logo" :class="`logo${index}`" alt="swiper-item">
              <div class="swiper_msg">
                <div class="swiper_bottom">
                  <p class="common_content">{{ item.msg_title }}{{ item.msg }}</p>
                  <!-- <p>{{ item.msg }}</p> -->
                </div>
              </div>
            </a>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content4">
      <h1 class="main-title">Socios comerciales</h1>
      <div class="img_list">
        <img class="img01" src="@/assets/img/floor7/belvo.png" alt="">
        <img class="img02" src="@/assets/img/floor7/STP.png" alt="">
        <img class="img03" src="@/assets/img/floor7/Mati.png" alt="">
        <template v-if="!$store.getters.GetIsH5">
          <img class="img04" src="@/assets/img/floor7/Kushki.png" alt="">
        </template>
      </div>
      <div class="img_list">
        <template v-if="$store.getters.GetIsH5">
          <img class="img04" src="@/assets/img/floor7/Kushki.png" alt="">
        </template>
        <img class="img05" src="@/assets/img/floor7/IMSS.png" alt="">
        <img class="img06" src="@/assets/img/floor7/EDOMEX.jpg" alt="">
        <img class="img07" src="@/assets/img/floor7/UNAM.png" alt="">
        <img class="img08" src="@/assets/img/floor7/AAPAUNAM.png" alt="">
        <img class="img09" src="@/assets/img/floor7/el_pibe.jpg" alt="">
      </div>
      <Fintech />
    </div>
  </div>
</template>

<script>
import { FloorSevenList } from './config'
import Fintech from '@/components/Fintech'
import { baseScroll } from '@/utils'
import { goScrollToPage } from '@/utils'
export default {
  components: {
    Fintech
  },
  computed: {
    dataList() {
      return FloorSevenList
    },
    goScrollToPage() {
      return goScrollToPage
    }
  },
  mounted () {
    baseScroll(this.$refs.floor7, 'step02')
  }
}
</script>

<style scoped lang='scss'>
.floor_seven {
  .content1 {
    padding-top: 50px;
    padding-bottom: 80px;
    background-color: var(--primary-blue);
    text-align: center;
    .title {
      padding: 0 20px;
      color: var(--primary-red);
    }
    .msg {
      margin: 25px 0;
      color: #fff;
      font-size: 35px;
    }
    .msg_pb {
      padding-top: 0;
      padding-bottom: 80px;
    }
    .btn {
      font-weight: bold;
      margin-bottom: 20px;
      display: inline;
      cursor: pointer;
      background-color: var(--primary-red);
      color: #fff;
      padding: 20px 80px;
      border-radius: 50px;
      font-size: 19px;
    }
  }
  .content2 {
    p {
      padding: 5px 0;
    }
    .p_pt {
      padding-top: 20px;
    }
    .p_pb {
      padding-bottom: 40px;
    }
    .img_list {
      .img01 {
        width: 70px;
        height: 70px;
      }
      .img02 {
        width: 180px;
        height: 35px;
      }
      .img03 {
        width: 140px;
        height: 70px;
      }
      .img04 {
        width: 140px;
        height: 60px;
      }
      .img05 {
        width: 190px;
        height: 40px;
      }
      .img06 {
        width: 190px;
        height: 45px;
      }
      .img07 {
        width: 190px;
        height: 60px;
      }
    }
  }
  .content2, .content4 {
    background-color: #f0f5f8;
    padding: 90px 0;
    text-align: center;
    p {
      font-size: 20px;
    }
    .img_list {
      margin: 20px auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      img {
        margin:0 20px;
      }
    }
  }
  .content3 {
    margin: 80px 0;
    padding: 0 200px;
    text-align: center;
    h1 {
      color: var(--primary-blue);
      padding: 30px 0px;
    }
    .el-carousel {
      ::v-deep .el-carousel__container {
        height: 480px;
        .el-carousel__item {
          .swiper_container {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 20px 0;
            .swiper_title_logo {
              position: absolute;
              top: 10px;
              padding: 20px 100px 0px 100px;
              background-color: #fff;
              color: #f20000;
              z-index: 99;
              left: 50%;
              transform: translateX(-50%);
            }
            .logo0 {
              width: 160px;
              height: 45px;
            }
            .logo1 {
              width: 190px;
              height: 35px;
            }
            .logo2 {
              width: 180px;
              height: 50px;
            }
            .logo3 {
              top: -10px;
              width: 140px;
              height: 90px;
            }
            .logo4 {
              top: -15px;
              width: 100px;
              height: 100px;
            }
            .logo5 {
              top: 0px;
              height: 55px;
              width: 160px;
            }
            .logo6 {
              top: -25px;
              height: 90px;
              width: 220px;
            }
            .logo7 {
              top: 0px;
              height: 60px;
              width: 160px;
            }
            .swiper_msg {
              position: absolute;
              top: 50px;
              width: 90%;
              height: 380px;
              margin: 0 50px;
              border-radius: 10px;
              border: solid 3px var(--primary-blue);
              .swiper_bottom {
                width: 100%;
                padding-top: 0px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                p {
                  line-height: 40px;
                  color: var(--primary-blue);
                  font-size: 25px;
                  word-break: break-word;
                }
              }
            }
          }
        }
      }
      ::v-deep .el-carousel__button {
        width: 15px;
        height: 15px;
        border: 1px var(--primary-blue) solid;
        border-radius: 50%;
        background-color: #fff;
      }
      ::v-deep.is-active .el-carousel__button {// 指示器激活按钮
        background: var(--primary-blue);
      }
    }
  }
  .content4 {
    background-color: #fff;
    .img_list {
      align-items: flex-end;
      padding: 40px 0;
      .img01 {
        width: 190px;
        height: 48px;
      }
      .img02 {
        width: 77px;
        height: 60px;
      }
      .img03 {
        width: 140px;
        height: 50px;
      }
      .img04 {
        width: 150px;
        height: 40px;
      }
      .img05 {
        width: 70px;
        height: 80px;
      }
      .img06 {
        width: 230px;
        height: 80px;
      }
      .img07, .img08, .img09 {
          width: 80px;
          height: 80px;
      }
    }

  }
}

@media screen and (max-width: 1246px) {
  .floor_seven {
    .content1 {
      padding-top: 65px;
      padding-bottom: 95px;
      .msg {
        padding: 30px 20px;
        padding-bottom: 80px;
        line-height: 40px;
      }
      .btn {
        font-weight: bold;
        font-size: 37px;
      }
    }
    .content2 {
      padding: 80px 0;
      p {
        font-size: 37px;
        width: 90%;
        margin: 0 auto;
        line-height: 40px;
      }
      .img_list {
        padding: 0 12px;
        .img01 {
          width: 70px;
          height: 70px;
        }
        .img02 {
          width: 200px;
          height: 40px;
        }
        .img03 {
          width: 180px;
          height: 90px;
          margin: 30px 0;
        }
        .img04 {
          width: 180px;
          height: 80px;
        }
        .img05 {
          width: 310px;
          height: 66px;
        }
        .img06 {
          width: 320px;
          height: 70px;
        }
        .img07 {
          width: 210px;
          height: 78px;
        }
      }
    }
    .content3 {
      padding: 0 0 !important;
      .el-carousel {
        ::v-deep .el-carousel__container {
          height: 600px !important;
          .el-carousel__item {
            .swiper_container {
              .swiper_title_logo {
                padding: 10px 25px !important;
              }
              .swiper_msg  {
                padding: 30px 0;
              }
              .logo0 {
                top: 15px;
              }
              .logo1 {
                top: 20px;
                width: 200px;
              }
              .logo3 {
                top: 0px;
                height: 85px;
              }
              .logo4 {
                top: 0px;
              }
              .logo5 {
                top: 13px;
                height: 55px;
              }
              .logo6 {
                top: -28px;
                height: 100px;
                width: 280px;
              }
              .logo7 {
                top: 10px;
                height: 62.5px;
                width: 165px;
              }
            }
          }
        }
      }
    }
    .content4 {
      .img_list {
        padding-bottom: 0;
        .img01 {
          width: 250px;
          height: 62px;
        }
        .img02 {
          width: 140px;
          height: 110px;
        }
        .img03 {
          width: 250px;
          height: 80px;
          margin-top: 30px;
        }
        .img04 {
          width: 280px;
          height: 70px;
        }
        .img05 {
          padding-top: 30px;
          margin-bottom: 0px !important;
          width: 70px;
          height: 80px;
        }
        .img06 {
          width: 380px;
          height: 130px;
        }
        .img07, .img08, .img09 {
          margin-top: 30px;
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}
</style>
