<template>
  <div class="floor5" ref="floor5">
    <div class="floor5_box">
      <div class="title common_title">¿Cómo funciona TiFi? </div>
      <div class="list_box">
        <div class="list" v-for="(item) in mapData" :key="item.id">
          <div class="img_wraper"><img :src="item.imgLink" alt="" class="list_img" /></div>
          <div class="right_arrows h5_hid" v-if="!item.arrowsNone">
            <i class="iconfont icon-yousanjiaoxing arrows"></i>
          </div>
          <div class="txt common_content">{{ item.txt }}</div>
        </div>
      </div>
      <div class="list_floor common_content">
        <span class="common_content_bold">$39.</span>por transacción sin importar el monto
      </div>
    </div>
  </div>
</template>

<script>
import { baseScroll } from '@/utils'
export default {
  data() {
    return {
      mapData: [
        {
          id: 0,
          imgLink: require('../../../assets/img/home/Ciculorojo.png'),
          txt: 'Solicitud de adelanto desde la App'
        },
        {
          id: 1,
          imgLink: require('../../../assets/img/home/Ciculorojo2.png'),
          txt: 'Verificación con cruce de información automatizado'
        },
        {
          id: 2,
          imgLink: require('../../../assets/img/home/Ciculorojo3.png'),
          txt: 'Depósito inmediato a la cuenta de nómina'
        },
        {
          id: 3,
          imgLink: require('../../../assets/img/home/Ciculorojo4.png'),
          txt: 'Cero pago de intereses',
          arrowsNone: true
        },
      ]
    }
  },
  mounted () {
    baseScroll(this.$refs.floor5, 'step01')
  }
}
</script>

<style scoped lang='scss'>
.floor5 {
  background-color: #e2efff;
  .floor5_box {
    width: 1440px;
    margin: 0 auto;
    padding-bottom: 32px;
    .title {
      font-style: normal;
      padding: 60px 0 48px;
      text-align: center;
      color: var(--primary-blue);
    }
    .list_box {
      display: flex;
      justify-content: space-around;
      .list {
        width: 200px;
        position: relative;
        .img_wraper {
          position: relative;
          margin: 0 auto;
          width: 200px;
          height: 200px;
          .list_img {
            position: absolute;
            z-index: 1;
            width: 200px;
            height: 200px;
            display: block;
          }
          &::after {
            content: ' ';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: #fff;
            border-radius: 50%;
          }
        }
        .right_arrows {
          position: absolute;
          top: 35%;
          right: -120px;
          transform: translateY(-50%);
          width: 120px;
          height: 1px;
          background: var(--primary-red);
          .arrows {
            position: absolute;
            right: -15px;
            top: 50%;
            transform: translateY(-51%);
            color: var(--primary-red);
          }
        }
        .txt {
          margin-top: 24px;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: var(--primary-blue);
        }
      }
    }
    .list_floor {
      text-align: center;
      padding: 50px 0;
      font-size: 20px;
      color: var(--primary-blue);
      font-weight: bold;
      span {
        font-weight: bold;
        font-size: 45px;
        margin-right: 50px;
        color: var(--primary-red);
        position: relative;
        &::after {
          content: '00';
          position: absolute;
          font-size: 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 1246px) {
  .floor5 {
    .floor5_box {
      width: 100%;
      .list_box {
        width: 100%;
        flex-wrap: wrap;
        .list {
          text-align: center;
          width: 100%;
          img {
            position: relative;
            margin: 0 auto;
          }
          .txt {
            font-size: 37px;
            line-height: 37px;
            margin-top: 30px;
            margin-bottom: 70px;
          }
        }
      }
      .list_floor {
        padding-bottom: 38px;
        font-size: 24px;
        span {
          font-size: 52px;
        }
      }
    }
  }
}
</style>
