<template>
	<div class="floor4 home-media">
		<div class="title"><span class="red">Los medios</span> hablan por nosotros</div>

		<el-carousel arrow="always" trigger="click" :interval="0" height="212px" indicator-position="none">
			<el-carousel-item v-for="(arr, index) in list" :key="index">
				<div class="item" v-for="item in arr" :key="item.name">
					<div class="item-main">
						<div class="item-bg item-box">
							<img :src="item.head" />

							<div class="con">
								<div class="con-title">
									<span>《</span>
									<a :href="item.link" target="_blank" rel="noopener noreferrer" v-html="item.title"> </a>
									<span>》</span>
								</div>
								<div class="con-footer">
									<label>{{ item.date }}</label>
									<a class="green" :href="item.link" target="_blank">{{ item.name }}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</el-carousel-item>
		</el-carousel>

		<el-dialog
			custom-class="el-dialog_media"
			:visible.sync="visible"
			width="80%"
			:before-close="() => (visible = false)"
		>
			<template slot="title"> <span class="red">Los medios </span> hablan por nosotros </template>

			<div class="model-item" v-for="item in modelList" :key="item.name">
				<img :src="item.head" alt="mib" />

				<div class="con">
					<label class="date"
						>{{ item.date }}<span class="green">{{ item.name }}</span></label
					>

					<p class="title" v-html="item.title"></p>

					<p class="link">
						<a target="_blank" rel="noopener noreferrer" class="red" :href="item.link">Ir a nota</a>
					</p>
				</div>
			</div>
		</el-dialog>

		<p class="red more" @click="showMore">Ver más</p>
	</div>
</template>
<script>
import MediaList from './config'
export default {
	data() {
		return {
			visible: false
		}
	},
	computed: {
		modelList() {
			return MediaList
		},
		list() {
			let res = []
			MediaList.forEach((_, index) => {
				if (index % 3 === 0) {
					res.push(MediaList.slice(index, index + 3))
				}
			})

			return res
		}
	},
	methods: {
		showMore() {
			this.visible = true
		}
	}
}
</script>
<style lang="scss">
.home-media {
	margin: 60px 0;
	.item {
		width: 33.33%;
		height: 100%;
		box-sizing: border-box;
		&-main {
			padding: 6px 12px 20px;
			box-sizing: border-box;
			height: 100%;
		}
		&-bg {
			box-shadow: -3px 0px 3px rgba(221, 221, 221, 0.3), 0px -1px 3px rgba(221, 221, 221, 0.3),
				3px 0px 3px rgba(221, 221, 221, 0.3), 0px 10px 10px rgba(221, 221, 221, 0.3);
			border-radius: 6px;
			height: 100%;
			width: 100%;
		}
	}
	.red {
		color: #fa5a5a;
	}
	.green {
		color: #a9ca6c !important;
	}
	.title {
		font-weight: bold;
		font-size: 30px;
		line-height: 54px;
		text-align: center;
		color: #1e3964;
		font-family: 'po';
	}
	.item-box {
		display: flex;
		padding: 32px 27px;
		box-sizing: border-box;
		color: #1e3964;
		font-size: 18px;
		& > img {
			width: 50px;
			height: 50px;
			box-shadow: 0 0 3px #ddd;
			border-radius: 50%;
		}
		a {
			word-break: break-all;
			word-wrap: break-word;
			color: #1e3964;
			font-size: 15px;
			&:hover {
				text-decoration: underline;
			}
		}
		.con {
			padding-left: 16px;
			flex: 1;
			display: flex;
			flex-flow: column;
			&-title {
				flex: 1;
			}
			&-footer {
				color: #a9a9a9;
				text-align: right;
				font-size: 13px;
				a {
					font-size: 13px;
					padding-left: 6px;
				}
			}
		}
	}
	.more {
		font-size: 14px;
		text-align: center;
		cursor: pointer;
	}
}

.web {
	.home-media {
		.item {
			width: 33.33%;
			float: left;
		}
	}
}
.mobile {
	.home-media {
		.item {
			width: 100%;
			float: left;
		}
	}
}

.el-dialog_media {
	padding: 0 17px;
	.el-dialog__headerbtn {
		line-height: 24px;
	}
	.el-icon-close {
		font-size: 13px;
		color: #282828;
		&:before {
			content: 'Cerrar';
		}
	}
	.el-dialog__header {
		font-family: 'po';
		font-size: 16px;
		border-bottom: 1px solid #bfbfbf;
		text-align: center;
	}
	.el-dialog__body {
		height: 60%;
		min-height: 600px;
		max-height: 850px;
		overflow: auto;
	}

	.model-item {
		display: flex;
		padding: 12px 16px;
		align-items: center;
		box-sizing: border-box;
		color: #1e3964;
		font-size: 18px;
		margin-bottom: 30px;
		border-radius: 6px;
		box-shadow: 0px -1px 3px rgba(221, 221, 221, 0.6), 3px 0px 3px rgba(221, 221, 221, 0.6),
			0px 10px 10px rgba(221, 221, 221, 0.6);
		& > img {
			width: 50px;
			height: 50px;
			box-shadow: 0 0 3px #ddd;
			border-radius: 50%;
		}
		a {
			word-break: break-all;
			word-wrap: break-word;
			color: #1e3964;
			font-size: 15px;
			&:hover {
				text-decoration: underline;
			}
		}
		.con {
			flex: 1;
			margin-left: 16px;
		}
		label {
			font-size: 14px;
		}
		.title {
			padding: 16px 0;
			font-size: 15px;
			line-height: 1.3;
			text-align: left;
		}
		.link {
			text-align: right;
			a {
				color: #fa5a5a;
			}
		}
		.date {
			color: #a9a9a9;
			font-size: 13px;
			span {
				margin-left: 8px;
			}
		}
	}
}
</style>
