<template>
  <div class="floor_three">
    <el-carousel :interval="5000" arrow="never" trigger="click">
      <el-carousel-item v-for="item in dataList" :key="item.author">
        <div class="swiper_container">
          <div class="swiper_msg">
            <h2 class="swiper_title common_content">{{ item.title }}</h2>
            <div class="swiper_bottom">
              <p class="text_wight common_content_bold">{{ item.author }}</p>
              <p class="common_content">{{ item.source }}</p>
            </div>
          </div>
          <img class="avatar" :src="item.avatar" :alt="item.author">
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { FloorThreeList } from './config'
export default {
  computed: {
    dataList() {
      return FloorThreeList
    }
  }
}
</script>

<style scoped lang='scss'>
.floor_three {
  width: 80%;
  padding: 0 50px;
  margin: 100px auto;
  .el-carousel {
    ::v-deep .el-carousel__container {
      height: 600px !important;
      .el-carousel__item {
        .swiper_container {
          position: relative;
          .swiper_msg {
            position: absolute;
            top: 110px;
            width: 90%;
            height: 400px;
            margin: 0 50px;
            border-radius: 10px;
            border: solid 3px var(--primary-blue);
            .swiper_title {
              padding: 0 20px;
              padding-top: 110px;
              text-align: center;
              word-break: break-word;
              color: var(--primary-blue);
              font-size: 32px;
              font-weight: bold;
            }
            .swiper_bottom {
              width: 100%;
              position: absolute;
              bottom: 15px;
              .text_wight {
                font-weight: bold;
              }
              p {
                color: var(--primary-red);
                font-size: 25px;
                text-align: center;
              }
            }
          }
          .avatar {
            background-color: #fff;
            padding: 0 20px;
            width: 200px;
            height: 200px;
            position: absolute;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    ::v-deep .el-carousel__button {
      width: 15px;
      height: 15px;
      border: 1px var(--primary-blue) solid;
      border-radius: 50%;
      background-color: #fff;
    }
    ::v-deep.is-active .el-carousel__button {// 指示器激活按钮
      background: var(--primary-blue);
    }
  }
}
@media screen and (max-width: 1246px) {
  .floor_three {
    width: auto;
    margin: 50px 0;
    .el-carousel {
      ::v-deep .el-carousel__container {
        height: 740px !important;
        .el-carousel__item {
          .swiper_container {
            .swiper_msg {
              height: 530px;
              .swiper_title {
                font-size: 36px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
