<template>
  <div>
    <div class="floor_eight" ref="floor8">
      <template v-if="!$store.getters.GetIsH5">
        <h1 class="main-title">Conócenos y mejora la salud financiera</h1>
        <h1 class="main-title">de tus colaboradores SIN COSTO</h1>
      </template>
      <template v-else>
        <p class="main-title">Conócenos y mejora la salud financiera de tus colaboradores SIN COSTO</p>
      </template>
    </div>

    <div class="form_padding"></div>
  </div>
</template>

<script>
import { baseScroll } from '@/utils'
export default {
  methods: {
    createFrom() {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "20157671",
        formId: "27717685-8432-483b-b4fd-a2c348ea2873"
      })
    }
  },
  mounted() {
    baseScroll(this.$refs.floor8, 'step04')
    const timeFrom = setTimeout(() => { // 生成底部表单
      if(window.hbspt) {
        clearInterval(timeFrom)
        this.createFrom()
      }
    }, 500);
  }
}
</script>

<style scoped lang='scss'>
.floor_eight {
  background-color: #ff4b4a;
  padding: 40px 0;
  .main-title {
    padding: 0 30px;
    color: #fff;
    text-align: center;
  }
}
.form_padding {
  width: 100%;
  padding-bottom: 1100px;
}
@media (min-width: 414px ) and (max-width: 1246px) {
  .form_padding {
    width: 100%;
    padding-bottom: 2000px;
  }
}
@media (max-width: 414px) {
  .form_padding {
    width: 100%;
    padding-bottom: 2800px;
  }
}
</style>
