<template>
  <div class="web">
    <floor-one :GoLink="GoLink"></floor-one>
    <floor-two :GoLink="GoLink"></floor-two>
    <floor-three></floor-three>
    <floor-four></floor-four>
    <floor-five></floor-five>
    <floor-fix></floor-fix>
    <floor-seven></floor-seven>
    <floor-eight></floor-eight>
  </div>
</template>
<script>
import Media from '@/components/Media/index'
import FloorOne from './floor1.vue'
import FloorTwo from './floor2.vue'
import FloorThree from './floor3.vue'
import FloorFour from './floor4.vue'
import FloorFive from './floor5.vue'
import FloorFix from './floor6.vue'
import FloorSeven from './floor7.vue'
import FloorEight from './floor8.vue'

export default {
	data() {
		return {}
	},
	methods: {
		GoToUrl(url) {
			this.$router.push(url)
		},
		GoLink(url) {
			window.open(url)
		},
    getClientWidth() {
      const width = document.documentElement.clientWidth
      if(width < 1231) {
        this.$store.commit('SetIsH5', true)
      } else {
        this.$store.commit('SetIsH5', false)
      }
    }
	},
  mounted () {
    this.getClientWidth()
    window.addEventListener('resize', () => {
      this.getClientWidth()
    })
  },
	components: {
		Media,
    FloorOne,
    FloorTwo,
    FloorThree,
    FloorFour,
    FloorFive,
    FloorFix,
    FloorSeven,
    FloorEight
	}
}
</script>
<style lang="scss" scoped>
// h1, h2, h3, p, span, button, div {
//   font-family: 'Poppins', sans-serif;
// }
@media screen and (min-width: 1246px) {
	.web {
		display: block;
		position: relative;
	}
}
</style>
